import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { IOrganization, ISimplifiedOrganization } from "../../interfaces/api-models";

@Injectable({
    providedIn: "root"
})
export class OrganizationsApiService {
    constructor(private readonly api: ApiService) {
    }

    get() {
        return this.api.get<IOrganization[]>("organizations");
    }

    getWithOutChartData() {
        return this.api.get<IOrganization[]>("organizations/withoutChartData").toPromise();
    }

    getSimplifiedOrganizations() {
        return this.api.get<ISimplifiedOrganization[]>("organizations/simplified").toPromise();
    }

    findByName(query: string) {
        return this.api.get<IOrganization[]>("organizations/search?query=" + query).toPromise();
    }

    getOrganizationById(orgId: string) {
        return this.api.get<IOrganization>("organizations/" + orgId);
    }
}
