
export interface IEntity {
    id: string;
    createdAt: string;
}

export interface ILog extends IEntity {
    duration: number;
    status: string;
    error: string;
    messageType: string;
    locationId: string;
    locationScheme: string;
    clientOrganizationName: string;
    clientOrganizationId: string;
    dataProviderOrganizationName: string;
    dataProviderOrganizationId: string;
}

export interface IDetailLog extends ILog {
    error: string;
    dataProviderRequest: string;
    dataProviderResponse: string;
    clientRequest: string;
    clientResponse: string;
    clientRequestLength: number;
    clientResponseLength: number;
}

export interface ILogs {
    logs: ILog[];
    totalCount: number;
}

export interface IOrganization extends IEntity {
    identifier: string;
    description: string;
    name: string;
    status?: string; // TODO surrogate
    type: OrganizationType;
    allRequestCount?: number;
    allSuccessRequestCount?: number;
    allFailedRequestCount?: number;
    allResponseCount?: number;
    allSuccessResponseCount?: number;
    allFailedResponseCount?: number;
    requestsPerHour?: number[];
    requestHours?: string[];
    canSendRequest: boolean;
    canReceiveRequest: boolean;
}

export interface ISimplifiedOrganization {
    id: string;
    identifier: string;
    name: string;
    type: IOrganizationTypeModel;
}

export interface IRole {
    id: string;
    name: string;
}

export interface IUserBase {
    id: string;
    email: string;
    phoneNumber: string;
}

export interface IUser extends IUserBase {
    roles: IRole[];
    organizationModels: IOrganization[];
}

export interface IOrganizationManagementModel {
    id: string;
    identifier: string;
    name: string;
    type: IOrganizationTypeModel;
    canSendRequest: boolean;
    canReceiveRequest: boolean;
    serviceUrl: string;
    authenticationUrl: string;
    region: string;
    emailInformations: IEmailInformationModel[];
    streetAddress: string;
    state: string;
    zipCode: string;
    city: string;
    country: string;
    languages: ILanguageModel[];
}

export interface IOrganizationManagementsModel {
    organizations: IOrganizationManagementModel[];
    totalCount: number;
}

export interface IEmailInformationModel {
    email: string;
    type: string;
}

export interface IOrganizationTypeModel {
    id: string;
    name: string;
    title: string;
    acronym: string;
}

export interface IOrganizationEmailModel {
    organizationIds: string[];
    subject: string;
    message: string;
    sendToAllOrganization?: boolean;
}

export interface IAddNewUser extends IUserBase {
    roleId: string;
    organizationIds?: string[];
}

export interface ICreationResult {
    succeeded: boolean;
    errors: string[];
}

export interface INotificationData {
    messages: string[];
}

export interface IChangePasswordModel {
    userId: string;
    oldPassword?: string;
    newPassword: string;
}

export interface IConfirmDialogData {
    title?: string;
    message: string;
}

export enum OrganizationType {
    FarmSoftware = 0,
    MilkRecordingOrganization = 1,
    CloudProvider = 2
}

export interface ILogStatistic {
    createdAt: Date;
    duration: number;
    status: number;
    clientOrganizationId: string;
    clientOrganization: string;
    dataProviderOrganizationId: string;
    dataProviderOrganization: string;
    messageType: number;
    locationId: string;
}

export interface ILanguageModel {
    id: string;
    code: string;
    displayName: string;
    organizationId: string;
    organizationName: string;
    enumResources: IEnumResourceModel[];
    baseLanguageId: string;
    lastSyncedAt: Date | undefined;
}

export interface ILanguagesModel {
    languages: ILanguageModel[];
    totalCount: number;
}

export interface IEnumResourceModel {
    id: string;
    adeName: string;
    hubName: string;
    resourceTexts: IResourceTextModel[];
}

export interface IResourceTextModel {
    id: string;
    key: string;
    value: string;
}

export interface ILibraryLanguageModel {
    id: string;
    languageId: string;
    languageName: string;
    OrganizationId: string;
    OrganizationName: string;
}