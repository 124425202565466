<mat-card class="mat-elevation-z8" mat-button [routerLink]="['/status', 'organization',  organization.id]">
   <mat-card-title>{{organization.name}}</mat-card-title>
   <mat-card-subtitle><span class="badge {{badgeColor}}">{{OrganizationType[organization.type]}}</span></mat-card-subtitle>
   <mat-card-subtitle>{{resources.Log.Status}}</mat-card-subtitle>
   <mat-card-content>
       <mat-list>
           <mat-list-item>
               <div mat-line>{{resources.Organization.Service}}<span class="badge {{serviceStatusBadgeColor}}">{{serviceStatus}}</span></div>
           </mat-list-item>
           <mat-list-item>
               <div mat-line>{{resources.Organization.TransactionTime}}<span class="badge warn">{{transactionTime}}</span></div>
           </mat-list-item>
       </mat-list>
   </mat-card-content>
   <mat-card-subtitle>{{canSendRequest ? resources.Organization.Requests : resources.Organization.Responses}}</mat-card-subtitle>
   <mat-card-content class="log-count">
       <mat-list>
           <mat-list-item>
               <div mat-line>{{resources.Organization.AllLogs}}<span class="badge primary">{{allLogCount}}</span></div>
           </mat-list-item>
           <mat-list-item>
               <div mat-line>{{resources.Organization.SucceededLogs}}<span class="badge primary">{{succeededLogCount}}</span></div>
           </mat-list-item>
           <mat-list-item>
               <div mat-line>{{resources.Organization.FailedLogs}}<span class="badge primary">{{failedLogCount}}</span></div>
           </mat-list-item>
       </mat-list>
   </mat-card-content>
</mat-card>
<loading></loading>
